@import 'variables';
@import 'root';
@import 'reboot';
@import 'mixins';
// @import 'base';


// @font-face {
//   font-family: 'sunbelticons';
//   src:
//     url('../../public/fonts/sunbelticons.ttf?2mh4rm') format('truetype'),
//     url('../../public/fonts/sunbelticons.woff?2mh4rm') format('woff'),
//     url('../../public/fonts/sunbelticons.svg?2mh4rm#sunbelticons') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

// add gray background or white background according to page displayed

body.spacommercepage.rt-gray-bg {
  .root.container {
    background: var(--sbr-neutral-gray-100);
  }

  .header {
    &__search,
    &__menu {
      background-color: var(--sbr-neutral-white);
    }
  }
}

/**
 * TODO: uncomment icons code when new icons are released.
 */
// .icon {
//     /* use !important to prevent issues with browser extensions that change fonts */
//     font-family: 'sunbelticons';
//     speak: never;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 1;
//     font-size: 1rem;

//     /* Better Font Rendering =========== */
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// .icon-arrow-ios-down:before {
//     content: "\e91b";
// }

// .icon-arrow-ios-left:before {
//     content: "\e91c";
// }

.icon-arrow-ios-right:before {
    content: "\e91d";
}

// .icon-arrow-ios-up:before {
//     content: "\e91e";
// }

// .icon-arrowhead-down:before {
//     content: "\e91f";
// }

// .icon-arrowhead-left:before {
//     content: "\e920";
// }

// .icon-arrowhead-right:before {
//     content: "\e921";
// }

// .icon-arrowhead-up:before {
//     content: "\e922";
// }

// .icon-chevron-down:before {
//     content: "\e923";
// }

// .icon-chevron-left:before {
//     content: "\e924";
// }

// .icon-chevron-right:before {
//     content: "\e925";
// }

// .icon-chevron-up:before {
//     content: "\e926";
// }

// .icon-arrow-circle-left:before {
//     content: "\e900";
// }

// .icon-arrow-circle-right:before {
//     content: "\e901";
// }

// .icon-arrow-circle-up:before {
//     content: "\e902";
// }

// .icon-arrow-circle-down:before {
//     content: "\e91a";
// }

// .icon-arrow-down:before {
//     content: "\e903";
// }

// .icon-arrow-left:before {
//     content: "\e905";
// }

// .icon-arrow-right:before {
//     content: "\e906";
// }

// .icon-arrow-up:before {
//     content: "\e908";
// }

// .icon-arrow-down-tringle:before {
//     content: "\e904";
// }

// .icon-arrow-right-tringle:before {
//     content: "\e907";
// }

// .icon-arrow-up-tringle:before {
//     content: "\e909";
// }

// .icon-arrrow-left-tringle:before {
//     content: "\e90a";
// }

// .icon-corner-down-left:before {
//     content: "\e90b";
// }

// .icon-corner-down-right:before {
//     content: "\e90c";
// }

// .icon-corner-left-down:before {
//     content: "\e90d";
// }

// .icon-corner-left-up:before {
//     content: "\e90e";
// }

// .icon-corner-right-down:before {
//     content: "\e90f";
// }

// .icon-corner-right-up:before {
//     content: "\e910";
// }

// .icon-corner-up-left:before {
//     content: "\e911";
// }

// .icon-corner-up-right:before {
//     content: "\e912";
// }

// .icon-diagonal-arrow-left-down:before {
//     content: "\e913";
// }

// .icon-diagonal-arrow-left-up:before {
//     content: "\e914";
// }

// .icon-diagonal-arrow-right-down:before {
//     content: "\e915";
// }

// .icon-diagonal-arrow-right-up:before {
//     content: "\e916";
// }

// .icon-flip:before {
//     content: "\e917";
// }

// .icon-flip-top:before {
//     content: "\e918";
// }

// .icon-swap:before {
//     content: "\e919";
// }