:root {
  // Custom variable values only support SassScript inside `#{}`.
  // @each $color, $value in $colors {
  //   --#{$variable-prefix}#{$color}: #{$value};
  // }

  // @each $color, $value in $theme-colors {
  //   --#{$variable-prefix}#{$color}: #{$value};
  // }

  @each $brandcolor, $value in $brandColors {
    --sbr-brand-#{$brandcolor}: #{$value};
  }
  @each $neutralcolor, $value in $neutralColors {
    --sbr-neutral-#{$neutralcolor}: #{$value};
  }
  @each $semanticcolor, $value in $semanticColors {
    --sbr-semantic-#{$semanticcolor}: #{$value};
  }
  @each $fontWeight, $value in $fontWeights {
    --sbr-font-weight-#{$fontWeight}: #{$value};
  }
  @each $lineHeight, $value in $lineHeights {
    --sbr-line-height-#{$lineHeight}: #{$value};
  }
  @each $buttonPadding, $value in $buttonPadding {
    --sbr-button-padding-#{$buttonPadding}: #{$value};
  }

  @for $i from 1 through 8 {
    --sbr-border-radius-#{$i}: #{$base-radius * calc($i / 16) * 1rem};
  }

  --#{sbr}-text-color: #{$sbr-text-color};
  --#{sbr}-text-normal-color: #{$sbr-text-normal-color};
  --#{sbr}-text-gray-color: #{$sbr-text-gray-color};
  --#{sbr}-text-lightGray-color: #{$sbr-text-lightGray-color};
  --#{sbr}-dt-shimmer: #{$sbr-dt-shimmer};
  --#{sbr-base}-font: #{$body};
  --#{sbr-base}-font-medium: #{$body-medium};
  --#{sbr-font-family}: #{$font-family};
}