*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: var(--sbr-text-color);
  font: var(--sbr-font-weight-400) var(--sbr-base-font) var(--sbr-font-family);
  line-height: var(--sbr-line-height-6);

}